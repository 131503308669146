@import "@theme/vars.scss";

.s-slogan {
    padding: 230px 0 100px;
    margin-top: -80px;

    @media only screen and (max-width: $bp-xl) {
        padding: 200px 0 80px;
    }

    @media only screen and (max-width: $bp-lg) {
        padding: 160px 0 40px;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 1288px;
        margin: 0 auto;
    }

    &__title {
        text-align: center;
        color: $dark-gray;
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 0;


      span{
            color: $pink;
        }
    }
}
