@mixin font($name, $weight, $fileName) {
    @font-face {
        font-family: $name;
        src: local("#{$name}"),
            url("@fonts/#{$name}/#{$fileName}.woff2") format("woff2"),
            url("@fonts/#{$name}/#{$fileName}.woff") format("woff"),
            url('@fonts/#{$name}/#{$fileName}.ttf') format('ttf');
        font-weight: $weight;
        font-style: normal;
        font-display: swap;
    }
}
  

// Template

@include font("Gotham Pro", 400, "Gotham Pro-Regular");
@include font("Gotham Pro", 500, "Gotham Pro-Medium");
@include font("Gotham Pro", 700, "Gotham Pro-Bold");