@import "@theme/vars.scss";

.s-benefits {
  padding-top: 88px;
  padding-bottom: 96px;
  box-sizing: border-box;
  position: relative;

  @media only screen and (max-width: $bp-md) {
    padding-top: calc(80px + 64.1vw);
    padding-bottom: 48px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: $bp-md) {
      display: block;
    }
  }

  &-info {
    width: 55.33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-width: $bp-md) {
      width: 100%;
    }

    &__subtitle {
      color: $pink-red;
      font-size: 23px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0;
      text-align: left;
      margin-bottom: 12px;

      @media only screen and (max-width: $bp-md) {
        width: 100%;
        font-size: 18px;
        line-height: 22px;
      }
    }

    h3 {
      color: $black;
      font-size: 35px;
      font-weight: 400;
      line-height: 42px;
      letter-spacing: 0;
      text-align: left;
    }

    &-cards {
      display: grid;
      width: 100%;
      grid-template-rows: repeat(3, 114px);
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      margin-top: 32px;

      @media only screen and (max-width: $bp-md) {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      &-item {
        display: flex;
        gap: 12px;
        align-items: center;
        box-sizing: border-box;
        padding: 24px;
        border: 1px solid $neutral;
        border-radius: 8px;

        @media only screen and (max-width: $bp-md) {
          padding: 12px;
        }

        &-img {
          width: 24px;
          height: 24px;
          display: flex;

          img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: contain;
          }
        }

        p {
          color: $black;
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0;
          text-align: left;

          @media only screen and (max-width: $bp-md) {
            padding: 12px;
          }
        }
      }
    }
  }

  &-img {
    width: 41%;
    display: flex;
    overflow: hidden;
    border-radius: 8px;

    @media only screen and (max-width: $bp-md) {
      width: calc(100% - 32px);
      position: absolute;
      top: 48px;
      right: 16px;
      left: 16px;
      height: 64.1vw;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
