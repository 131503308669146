@import "@theme/vars.scss";

.badges {

    &__list{

        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;
        
        // .slider{

        //     &__inner{
        //         overflow: visible;
        //     }

        //     &__wrapper{
        //     }

        //     &__slide{
        //         width: auto;
        //         margin-right: 10px !important;
        //     }
        // }
    }
    
    .badge{
        margin-right: 10px;
        margin-bottom: 10px;

        &:last-of-type{
            margin-right: 0;
        }
    }

}

.badge {
    cursor: pointer;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    height: 64px;
    text-decoration: none;
    font-family: 'HelveticaNeue';
    font-weight: 700;
    font-size: 18px;
    line-height: 135%;
    color: $blue;
    fill: $blue;
    white-space: nowrap;


    &:hover &__wrapper{
        background-color: rgba(254, 201, 10, 0.1);
    }

    &--md{
        height: 47px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        @media only screen and (max-width: $bp-lg) {
            height: 35px;
            font-size: 12px;
            line-height: 15px;
        }
    }

    &__input:checked + &__wrapper,
    &.is-active &__wrapper {
        box-shadow: 9px 13px 39px 5px rgba(251, 199, 7, 0.25);
        background-color: $pink;
    }

    &__input {
        display: none;
    }

    &__wrapper {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        border-radius: 10px;
        text-decoration: none;
        color: inherit;
        border: 1px solid $pink;
        transition: background-color 0.35s ease, box-shadow 0.35s ease;

        @media only screen and (max-width: $bp-lg) {
            padding: 0 15px;
            border-radius: 7px;
        }

        span + .icon {
            margin-left: 5px;
        }

        .icon + span {
            margin-left: 5px;
        }
    }
}
