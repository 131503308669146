@import "@theme/vars.scss";

.s-intro {
    position: relative;

    &__bg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__inner {
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
        height: 100vh;
        min-height: 1115px;
        max-height: 1440px;
        padding: 138px 0;

        @media only screen and (max-width: $bp-xl) {
            min-height: 920px;
        }

        @media only screen and (max-width: $bp-lg) {
            padding: 95px 0;
            min-height: 820px;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        color: #fff;
        margin-bottom: 44px;
        text-align: center;

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 32px;
        }

        @media only screen and (max-width: $bp-md) {
            margin-bottom: 24px;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }

    &__button {
    }
}