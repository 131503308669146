@import "@theme/vars.scss";

.select {
    width: auto;
    min-width: 172px;
    flex-grow: 1;
    height: 47px;
    font-family: 'HelveticaNeue';
    font-size: 14px !important;
    line-height: 14px !important;

    .SumoSelect {
        width: 100%;
        height: 100%;

        &:focus > .CaptionCont, 
        &:hover > .CaptionCont, 
        &.open > .CaptionCont{
            box-shadow: none;
            border-color: #AEBDCB;
        }
    }

    .sumo {
    
        &_select {
        }
    }

    .SumoUnder {
        display: none;
    }

    .CaptionCont {
    }

    .SumoSelect.open .SelectBox label {
        rotate: -180deg;
    }

    .SelectBox {
        cursor: pointer;
        padding: 0 12px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        height: 100%;
        border-radius: 8px;
        border: 1px solid #AEBDCB;
        transition: border-color 0.35s ease;
        position: absolute;
        width: 100%;

        &:hover{
            border-color: $blue-light;
        }
        
        .placeholder{
            font-style: normal !important;
            color: $dark !important;
        }


        label{
            margin: auto 15px auto 0;
            cursor: pointer;
            width: 20px !important;
            height: 20px !important;
            transition: rotate 0.35s ease;

            i{
                width: 100% !important;
                height: 100% !important;
                opacity: 1 !important;
                background-size: 100% !important;
                background-position: center !important;
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.2659 5H15.801C15.7014 5 15.6077 5.04883 15.5491 5.12891L10.0002 12.7773L4.45142 5.12891C4.39283 5.04883 4.29908 5 4.19947 5H2.73462C2.60767 5 2.53345 5.14453 2.60767 5.24805L9.49439 14.7422C9.74439 15.0859 10.2561 15.0859 10.5042 14.7422L17.3909 5.24805C17.467 5.14453 17.3928 5 17.2659 5V5Z' fill='%23FECC00'/%3E%3C/svg%3E%0A") !important;
            }
        }
    }

    .optWrapper {
        display: block !important;
        opacity: 0 !important;
        visibility: hidden !important;
        border-radius: 12px;
        box-shadow: 9px 12px 42px 5px rgba(18, 66, 111, 0.09);
        width: 100%;
        padding: 10px;
        top: 100% !important;
        transform: translateY(0);
        transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;
    }

    .SumoSelect.open .optWrapper{
        opacity: 1 !important;
        visibility: visible !important;
        transform: translateY(15px);
    }

    .options {
        max-height: 410px;
        overflow-y: auto;

        @media only screen and (max-width: $bp-lg) {
            max-height: 284px;
        }
    }

    .opt {
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        padding: 5px !important;
        border: none !important;
        color: $blue-dark;
        transition: text-shadow 0.35s ease;

        span{
            position: relative !important;
            margin-left: 0 !important;
            flex-shrink: 0;
            width: 25px !important;
            height: 25px !important;
            margin: 3.5px 10px 3.5px 0 !important;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid #AEBDCB !important;
            transition: border-color 0.35s ease, background-color 0.35s ease;

            &::after{
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100% !important;
                height: 100% !important;
                background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.77273 15.7015L6.19318 12.2537L5 13.403L9.77273 18L20 8.14925L18.8068 7L9.77273 15.7015Z' fill='%23262626'/%3E%3C/svg%3E%0A") !important;
                background-size: 100%;
                background-position: center;
                opacity: 0;
                transform: translate(-50%, -50%) scale(0.4);
                will-change: transform;
                transition: opacity 0.35s ease, transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;

            }

            i{
                display: none !important;
            }
        }

        label{
            margin: auto 0;
        }

        &:hover{
            background-color: transparent !important;
            text-shadow: 0px 0px 1px $blue-dark;

            span{
                border-color: $pink !important;
            }
        }

        &.selected{
            text-shadow: 0px 0px 1px $blue-dark;

            span{
                border-color: $pink !important;
                background-color: $pink !important;

                &::after{
                    content: "";
                    transform: translate(-50%, -50%) scale(1);
                    opacity: 1;
                }
            }
        }
    }

    .selected {
    }
    
}
