@import "@theme/vars.scss";

.s-stack {
  position: relative;
  box-sizing: border-box;
  padding-top: 120px;
  padding-bottom: 120px;
  background: #000000 ;
  overflow: hidden;

  @media only screen and (max-width: $bp-md) {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  &:before {
    content: '';
    width: 1033px;
    height: 944px;
    position: absolute;
    bottom: -500px;
    left: -500px;
    transform: rotate(230deg);
    background: linear-gradient(98.24deg, #B74A89 0%, #EE5D87 100%);
    filter: blur(150px);

    @media only screen and (max-width: $bp-md) {
      bottom: 30%;
      left: -1000px;
    }
  }

  &-list {
    position: relative;
    z-index: 4;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-top: 72px;

    @media only screen and (max-width: $bp-md) {
      flex-direction: column;
      gap: 16px;
      margin-top: 32px;
    }

    &-item {
      box-sizing: border-box;
      padding: 42px 24px;
      box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.12);
      background: rgba(188, 172, 176, 0.4);
      border-radius: 8px;
      width: calc(20% - 26px);
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;

      @media only screen and (max-width: $bp-md) {
        width: 100%;
        padding: 16px;
      }

      p {
        color: #FFFFFF;
        font-size: 23px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0;
        text-align: center;
      }

      &-img {
        width: 100%;
        height: 72px;
        display: flex;

        img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: contain;
        }
      }
    }
  }
}
