@import "@theme/vars.scss";

button,
.button,
a {
    cursor: pointer;
    padding: 0;
    background: none;
    border: none;
    outline: none;
}

.button {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    height: 46px;
    border-radius: 8px;
    font-family: 'Gotham Pro';
    text-decoration: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    background-color: $pink;
    color: #fff;
    fill: #fff;
    transition: color 0.35s ease, fill 0.35s ease, border-color 0.35s ease, background-color 0.35s ease, opacity 0.35s ease, box-shadow 0.35s ease;

    @media only screen and (max-width: $bp-lg) {
        height: 38px;
    }

    &__text{

    }

    &__icon{
        flex-shrink: 0;
        width: 30px;
        height: 30px;
    }

    &__text + &__icon{
        margin-left: 10px;
    }

    &__icon + &__text{
        margin-left: 10px;
    }

    &:hover{
        background-color: #C9003E;
    }

    &[disabled]{
        pointer-events: none;
        opacity: 0.5;
    }


    // &--border{
    //     color: $yellow;
    //     fill: $yellow;
    //     background-color: transparent;

    //     &:hover{
    //         background-color: $yellow;
    //         border-color: $yellow;
    //         color: #fff;
    //         fill: #fff;
    //     }
    // }

    // &--border#{&}--white{
    //     border-color: #fff;
    //     fill: #fff;
    //     color: #fff;
    //     background-color: transparent;

    //     &:hover{
    //         border-color: #fff;
    //         background-color: #fff;

    //     }
    // }

    &--icon{
        padding: 0;
        width: 46px;
    }
}
