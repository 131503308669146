@import "@theme/vars.scss";

html {
  font-family: $defaultFontFamily;
  font-size: $defaultFontSize;
  line-height: $defaultLineHeight;
  color: $blue-dark;
  background-color: #FFF;
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
    background-color: #fff;

    &._lock {
        overflow: hidden;
    }
}

*{
    -webkit-tap-highlight-color: transparent;
    scrollbar-color: $pink #DEE5E8;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background-color: #DEE5E8;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $pink;
    }
}

img{
  image-rendering: -webkit-optimize-contrast;
}

.main{
    flex-grow: 1;
    overflow-x: hidden;
    width: 100%;

    // .breadcrumbs + section{
    //     padding-top: 0;
    // }

    section{
        // &:first-of-type{
        //     padding-top: 0;
        // }

        // &:last-child{
        //     padding-bottom: 50px;
        // }
    }
}

.container {
    box-sizing: border-box;
    width: 100%;
    max-width: 1730px;
    padding: 0 85px;
    margin: 0 auto;

    @media only screen and (max-width: $bp-xxl) {
        padding: 0 50px;
    }

    @media only screen and (max-width: $bp-xl) {
        padding: 0 32px;
    }

    @media only screen and (max-width: $bp-lg) {
        padding: 0 24px;
    }

    @media only screen and (max-width: $bp-md) {
        padding: 0 8px;
    }
}

.subtitle {
  color: #FFFFFF;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: 0;
  text-align: left;
  position: relative;
  z-index: 3;

  @media only screen and (max-width: $bp-md) {
    font-size: 35px;
    line-height: 42px;
  }
}
