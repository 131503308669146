@import "@theme/vars.scss";

.input{
    position: relative;
    box-sizing: border-box;
    background: none;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 300px;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    transition: padding 0.35s ease;

    @media only screen and (max-width: $bp-md) {
        font-size: 14px;
    }

    &--md{
    }

    &--md &__field{
        height: 42px;
        border-radius: 5px;
        border-width: 0.5px;
    }

    &__field{
        outline: none;
        box-sizing: border-box;
        width: 100%;
        height: 54px;
        padding: 0 15px;
        background-color: #fff;
        color: #262626;
        border: 1px solid #AEBDCB;
        border-radius: 10px;
        transition: background-color 0.35s ease 0.1s, border-color 0.35s ease, color 0.35s ease;

        @media only screen and (max-width: $bp-lg) {
            height: 50px;
        }

        // &:focus{
		//     border-color: $pink;
        // }
    }

    &__error{
        color: $red;
        font-size: 12px;
        line-height: 14px;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 100%;
        transition: transform 0.35s ease, opacity 0.35s ease, visiblity 0.35s ease;
    }

    &::placeholder{
        color: #8B9BA7;
        opacity: 0.5;
    }

    &.is-error{
        padding-bottom: 20px;
    }

    &.is-error &__field{
        border-color: $red;
    }

    &.is-error &__error{
        opacity: 1;
        visibility: visible;
    }

    &.is-filled &__field{
        border-color: $pink;
    }

	&.is-focus &__field{
		border-color: $pink;
	}

}