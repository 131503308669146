// COLORS

$dark: #18171C;
$black: #3B3B3B;
$dark-gray: #292929;

$white: #fff;
$white-bg: #F5F5F5;
$white-gray: #F6F6F6;
$neutral: #CFCFCF;

$pink: #E31A4E;
$pink-red: #E61F50;
$blue: #003042;
$blue-dark: #1D3A49;
$blue-light: #0081AF;
$blue-white: #AEBDCB;

$red: #CF1322;




// DOCUMENT
$defaultFontFamily : 'Gotham Pro', sans-serif;
$defaultFontSize : 16px;
$defaultLineHeight: 145%;

// BREAKPOINTS

$bp-sm: 575px;
$bp-md: 833px;
$bp-lg: 991px;
$bp-xl: 1259px;
$bp-xxl: 1439px;
