@import "@theme/vars.scss";

.footer {
    box-sizing: border-box;
    padding: 32px 0;
    background-color: $dark;
    color: #fff;

    @media only screen and (max-width: $bp-lg) {
        padding: 24px 0;
    }

    @media only screen and (max-width: $bp-md) {
        padding: 20px 0;
    }

    &__container {
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;

        &>*:last-child{
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: 700;
        margin-bottom: 20px;

        @media only screen and (max-width: $bp-md) {
          font-size: 28px;
          line-height: 36px;
        }
    }

    &__link {
        font-size: 23px;
        line-height: 120%;
        margin-bottom: 20px;

        @media only screen and (max-width: $bp-lg) {
            font-size: 18px;
            margin-bottom: 15px;
        }

        @media only screen and (max-width: $bp-md) {
          font-size: 23px;
          line-height: 28px;
        }
    }

    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 -12px;

        @media only screen and (max-width: $bp-lg) {
            margin: 0 -8px;
        }

        @media only screen and (max-width: $bp-md) {
          flex-direction: column;
          gap: 8px;
          align-items: flex-start;
        }
    }

    &__info-text {
        box-sizing: border-box;
        display: inline-flex;
        color: #BAB9BB;
        font-size: 23px;
        line-height: 120%;
        margin: 12px 0 0;
        padding: 0 12px;
        flex-basis: 1 0 33.33%;


        @media only screen and (max-width: $bp-lg) {
            font-size: 18px;
            margin: 8px 0 0;
            padding: 0 8px;
        }

        @media only screen and (max-width: $bp-md) {
          font-size: 23px;
          line-height: 28px;
          margin: 0;
        }
    }
}
