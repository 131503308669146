@import "@theme/vars.scss";
@import "@theme/animations.scss";

.header {
    box-sizing: border-box;
    position: fixed;
    top: 14px;
    right: 0;
    z-index: 10;
    width: 100%;
    padding: 10px 0;
    transition: background-color 0.35s ease, top 0.35s ease 0.35s;

    &.open .header__nav{
        @media only screen and (max-width: $bp-md) {
            transform: translateX(-100%);

        }
    }

    &.open .header__burger-btn span {
     background-color: #E31A4E;
    }

    &.open .header__burger-btn span:nth-child(1) {
     transform: translateY(0) rotate(45deg);
    }

    &.open .header__burger-btn span:nth-child(2) {
      opacity: 0;
    }

    &.open .header__burger-btn span:nth-child(3) {
      transform: translateY(0) rotate(-45deg);
    }

    &__burger-btn {
      z-index: 66 ;
      display: none;
      width: 40px;
      height: 40px;
      border: none;
      position: relative;

      @media only screen and (max-width: $bp-md) {
        display: block;
      }

      span {
        position: absolute;
        width: 34px;
        height: 3px;
        background-color: #FFFFFF;
        border-radius: 2px;
        left: 3px;
        transition: transform .5s, opacity .5s, background-color .5s;

        &:nth-child(1) {
          transform: translateY(-10px);
        }
        &:nth-child(3) {
          transform: translateY(10px);
        }
      }
    }

    &.is-scrolled{
        top: 0;
        background-color: rgba(41, 41, 41, 0.5);
        transition: background-color 0.35s ease 0.35s, top 0.35s ease;
    }

    @media only screen and (max-width: $bp-lg) {
        top: 0;
    }

    &__container {
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        display: flex;
        align-items: center;
        width: 272px;
        height: 90px;
        object-fit: contain;
        transition: filter 0.5s ease;

        @media only screen and (max-width: $bp-lg) {
            width: 196px;
            height: 65px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &:hover{
            filter: drop-shadow(0px 0px 10px rgba(241, 62, 110, 0.5));
        }
    }

    &__nav {
        display: flex;
        align-items: center;
        margin: 0 -12px 0 0;

        @media only screen and (max-width: $bp-lg) {
            margin: 0 -8px 0 0;
        }

        @media only screen and (max-width: $bp-md) {
          position: absolute;
          right: -85.13vw;
          top: 0;
          flex-direction: column;
          margin: 0;
          gap: 40px;
          align-items: flex-start;
          background-color: #FFFFFF;
          width: 85.13vw;
          height: 100vh;
          box-sizing: border-box;
          padding: 32.14vh 82px 18px 81px;
          z-index: 10;
          transition: transform .5s;
        }
    }

    &__link {
      font-size: 1.38em;
        line-height: 120%;
        margin: 0 20px;
        flex-shrink: 0;

        @media only screen and (max-width: $bp-lg) {
            font-size: 1.13em;
            margin: 0 8px;
        }

        @media only screen and (max-width: $bp-md) {
          font-size: 1.38em;
          line-height: 26px;
          letter-spacing: 0;
          color: #300C0F;
          display: block;
        }
    }
}
