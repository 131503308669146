@import "@theme/vars.scss";

.s-clients {
  position: relative;
  min-height: 626px;
  box-sizing: border-box;
  padding-top: 120px;
  padding-bottom: 120px;
  background: #000000;
  overflow: hidden;

  @media only screen and (max-width: $bp-md) {
    min-height: auto;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  &:before {
    content: '';
    width: 380px;
    height: 722px;
    position: absolute;
    top: -50px;
    right: 437px;
    filter: blur(150px);
    transform: rotate(-143deg);
    background: linear-gradient(98.24deg, #B74A89 0%, #EE5D87 100%);

    @media only screen and (max-width: $bp-md) {
      right: auto;
      top: 260px;
      height: 500px;
    }
  }

  .container {
    display: flex;
    align-items: center;
    gap: 20.34%;

    @media only screen and (max-width: $bp-md) {
      flex-direction: column;
      gap: 32px;
      align-items: self-start;
    }
  }

  &-title {
    width: 39.99%;

    @media only screen and (max-width: $bp-md) {
      width: 100%;
    }

    .subtitle {
      @media only screen and (max-width: $bp-md) {
        width: 60%;
      }
    }

    p {
      position: relative;
      z-index: 5;
      color: #CFCFCF;
      font-size: 23px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0;
      text-align: left;
      margin-top: 24px;

      @media only screen and (max-width: $bp-md) {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0;
        margin-top: 16px;
      }
    }
  }

  &-list {
    width: 27.79%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    z-index: 4;

    @media only screen and (max-width: $bp-md) {
      width: 100%;
    }

    &-item {
      position: relative;
      padding: 32px 24px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;

      @media only screen and (max-width: $bp-md) {
        padding: 13px 16px;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(98.24deg, #B64A89 0%, #EE5D87 0.01%);
      }

      &:last-child {

        &:before {
          display: none;
        }
      }

      &-img {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background: $pink;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 24px;
          height: 24px;
          object-fit: contain;
          object-position: center;
        }
      }

      p {
        color: #FFFFFF;
        width: 72.27%;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0;
        text-align: left;

        @media only screen and (max-width: $bp-md) {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
}
