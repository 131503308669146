@import "@theme/vars.scss";

.s-services {
    padding: 144px 0 120px;
    margin-top: -80px;
    box-sizing: border-box;

    @media only screen and (max-width: $bp-xl) {
        padding: 120px 0 48px;
    }

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1260px;
        margin: 0 auto 80px;

        @media only screen and (max-width: $bp-md) {
          margin: 0 auto 32px;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }

    &__title {
        padding-bottom: 8px;
        margin-bottom: 40px;
        border-bottom: 2px solid $pink;
    }

    &__subtitle {
        font-size: 28px;
        line-height: 130%;
        color: #4E4E4E;
        text-align: center;

        @media only screen and (max-width: $bp-lg) {
            font-size: 24px;
        }

        @media only screen and (max-width: $bp-md) {
          font-size: 18px;
        }
    }

    &__inner {
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px -40px;

        @media only screen and (max-width: $bp-lg) {
            margin: 0 -10px -20px;
        }
    }

    &__item {
        box-sizing: border-box;
        display: flex;
        position: relative;
        cursor: pointer;
        width: calc(33.33% - 40px);
        margin: 0 20px 40px;
        height: 500px;
        text-decoration: none;
        overflow: hidden;
        border-radius: 8px;

        @media only screen and (max-width: $bp-xl) {
            height: 520px;
        }

        @media only screen and (max-width: $bp-lg) {
            height: 460px;
            width: calc(50% - 20px);
            margin: 0 10px 20px;
        }

        @media only screen and (max-width: $bp-md) {
            width: 100%;
            height: 71.79vw;
        }

        &::after{
            border-radius: 8px;
            content: "";
            background-color: $dark;
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            inset: 0 0 0 0;
            opacity: 0;
            overflow: hidden;
            transition: opacity 0.5s ease;
        }

        &:hover::after{

            opacity: 0.35;

        }
    }

    &__item:hover &__item-bg{
        transform: scale(102%);
    }

    &__item-bg {
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        position: absolute;
        inset: 0 0 0 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 1.25s ease;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__item-inner {
        box-sizing: border-box;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 24px;

        @media only screen and (max-width: $bp-md) {
          padding: 24px 11px;
        }
    }

    &__item-title {
      font-size: 23px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0;
      text-align: left;
      color: #FFFFFF;

    }
}
