@import "@theme/vars.scss";

.checkbox {
    cursor: pointer;
    display: flex;

    &__input {
        display: none;
    }

    &__input:checked + &__wrapper &__icon-wrapper{
        border-color: transparent;
        background-color: $pink;
    }

    &__input:checked + &__wrapper &__icon{
        transform: scale(1);
        opacity: 1;
    }

    &:hover &__icon-wrapper{
        border-color: $pink;
    }

    &__wrapper {
        display: flex;
        align-items: flex-start;
    }

    &__icon-wrapper {
        box-sizing: content-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid #AEBDCB;
        transition: border-color 0.35s ease, background-color 0.35s ease;
    }

    &__icon {
        width: 100% !important;
        height: 100% !important;
        fill: $blue;
        opacity: 0;
        transform: scale(0.4);
        will-change: transform;
        transition: opacity 0.35s ease, transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.1s;
    }

    &__content {
        display: flex;
        margin: auto 0 auto 10px;

        span{
            display: inline;

            .tooltip-trigger{
                display: inline;
                margin-left: 5px;
            }
        }
    }



    &--md &__icon-wrapper{
        width: 18px;
        height: 18px;
        border-radius: 3px;
    }
}