@import '@theme/vars.scss';

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
}

p{
    font-weight: 400;
}


.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 700;
    line-height: 120%;
}

.h1{
    font-size: 69px;

    @media only screen and (max-width: $bp-lg) {
        font-size: 58px;
    }

    @media only screen and (max-width: $bp-md) {
        font-size: 44px;
    }
    
}

.h2{
    font-size: 55px;

    @media only screen and (max-width: $bp-lg) {
        font-size: 48px;
    }

    @media only screen and (max-width: $bp-md) {
        font-size: 40px;
    }
}

.h3{
    font-size: 44px;

    @media only screen and (max-width: $bp-lg) {
        font-size: 40px;
    }

    @media only screen and (max-width: $bp-md) {
        font-size: 35px;
    }
}

.h4{
    font-size: 35px;

    @media only screen and (max-width: $bp-lg) {
        font-size: 30px;
    }

    @media only screen and (max-width: $bp-md) {
        font-size: 28px;
    }
}

.h5{
    font-size: 28px;
    line-height: 130%;

    @media only screen and (max-width: $bp-lg) {
        font-size: 24px;
    }

    @media only screen and (max-width: $bp-md) {
        font-size: 22px;
    }
}

.h6{
    font-size: 23px;
    line-height: 130;
}

.text{
    font-size: 18px;
    line-height: 120%;

    &-xl{
        font-size: 23px;
        line-height: 120%;
    }

    &-md{
        font-size: 14px;
        line-height: 120%;
    }

    &-sm{
        font-size: 12px;
        line-height: 120%;
    }

    //STYLE

    &-border{
        -webkit-text-stroke: 1px #8C8C8C;
    }

    //WEIGHT

    &-regular{
        font-weight: 400;
    }
    
    &-medium{
        font-weight: 500;
    }

    &-bold{
        font-weight: 700;
    }

    //FAMILY


    &-century{
        font-family: 'CenturyGothic';
    }

    &-helvetica{
        font-family: 'Helvetica';
    }

    &-neue{
        font-family: 'Gotham Pro';
    }
}