@import "@theme/vars.scss";

.s-slider {

  .swiper {
    height: 800px;

    @media only screen and (max-width: $bp-md) {
      height: 82vw;
    }

    &-wrapper {

    }

    &-slide {
      position: relative;
      border-radius: 8px;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        left: 50px;
        bottom: -35%;
        width: 30%;
        height: 55%;
        filter: blur(170px);
        transform: rotate(-67deg);
        z-index: 2;
        background: linear-gradient(100.62deg, #B74A89 -11.46%, #EE5D87 97.47%);

        @media only screen and (max-width: $bp-md) {
          height: 100%;
          filter: blur(90px);
        }
      }

      img {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      &-info {
        position: absolute;
        z-index: 3;
        background: $white-bg;
        border-radius: 12px;
        padding: 24px 32px;
        left: 32px;
        bottom: 32px;
        max-width: 51.35%;

        @media only screen and (max-width: $bp-md) {
          left: 12px;
          right: 12px;
          bottom: 12px;
          max-width: 100%;
          padding: 16px;
        }

        p {
          color: $black;
          font-size: 28px;
          font-weight: 400;
          line-height: 34px;
          letter-spacing: 0;
          text-align: left;

          @media only screen and (max-width: $bp-md) {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
