@import '@theme/vars.scss';

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/transitions";

.fade{
	transition: opacity 0.25s linear;
}
.modal {
	&.fade &-dialog{
        transform: none !important;
	}

	&.fade &-content{
		transform: translateY(15px);
        opacity: 0;
		transition: transform 0.25s ease-out, opacity 0.25s ease;

	}

	&.show &-content{
        opacity: 1;
		transform: none;
	}

	&-backdrop{
		&.show{
			background: #000;
			opacity: 0.45;
		}
	}

	&-dialog {
		border-radius: 0;
		border: none;
		max-width: 380px;
		margin-right: auto;
		margin-left: auto;

        @media only screen and (max-width: $bp-lg) {
            padding: 0 30px;
        }
	}

	&-content {
		box-sizing: border-box;
		border: none;
		background-color: #fff;
		border-radius: 10px;
        padding: 22px 40px 35px;
		border-radius: 10px;
		display: flex;
        box-shadow: 4px 5px 19px rgba(40, 107, 164, 0.2);
        
        @media only screen and (max-width: $bp-lg) {
            padding: 22px 30px;
        }
	}

	&-close {
		width: 24px;
		height: 24px;
		background-color: transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		cursor: pointer;
        margin-left: auto;
        margin-bottom: 10px;

		.icon {
			width: 100%;
			height: 100%;
			transition: fill 0.35s ease;
			fill: $blue-dark;

			@media only screen and (max-width: $bp-md) {
				width: 24px;
				height: 24px;
			}
		}

		&:hover {
			.icon{
				fill: $blue-light;
			}
		}
	}

	&__main{
		display: flex;
		flex-direction: column;
	}

    &__header{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;
        
        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 20px;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }

        
	&__title{
        text-align: center;
        font-family: 'CenturyGothic';
        font-size: 25px;
        line-height: 125%;

        span{
            color: $pink;
        }
    }

    &__subtitle{
        text-align: center;
        margin-top: 15px;
    }


	&__form{
		display: flex;
		flex-direction: column;
		flex-grow: 1;

		form{
			display: flex;
			flex-direction: column;
			flex-grow: 1;
		}

        .input{
            max-width: none;
        }

        .checkbox{
            a{
                color: $pink;
            }
        }

        .button{
            width: 100%;
        }
	}

    &__form-block{
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        &:last-child{
            margin-bottom: 0px;
        }
    }

    &__form-row{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;

        &:last-of-type{
            margin-bottom: -10px;
        }
    }

    &__form-col{
        flex: 1 0 calc(50% - 10px);
        margin: 0 5px 10px;
    }





	&--md &-dialog{
        max-width: 354px;
	}
	
	&--md &-content{
		padding: 30px;

	}


    &--chanels{

    }

    
	&--chanels &-dialog{
        max-width: 828px;

	}
	
	&--chanels &-content{
        padding: 22px 40px;

        @media only screen and (max-width: $bp-lg) {
            padding: 22px 20px;
        }
	}

    &--chanels &-close{
        position: absolute;
        top: 22px;
        right: 40px;

        @media only screen and (max-width: $bp-lg) {
            position: static;
            margin-bottom: 0;
        }
    }

    &--chanels &__header{
        align-items: flex-start;

        @media only screen and (max-width: $bp-lg) {
            margin-bottom: 15px;
        }
    }

    &--chanels &__title{
        text-align: left;

        @media only screen and (max-width: $bp-lg) {
            font-size: 22px;
        }
    }

    &__chanels{
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        margin: 0 -12.5px;
        max-height: 570px;
        min-height: 150px;
        overflow-y: auto;

        @media only screen and (max-width: $bp-lg) {
            max-height: 520px;
        }

        @media only screen and (max-width: $bp-md) {
            max-height: 470px;
        }
    }

    &__chanel{
        box-sizing: border-box;
        width: 25%;
        display: flex;
        margin: 0 0 15px;
        padding: 0 12.5px;

        @media only screen and (max-width: $bp-xl) {
            width: 33.33%;
        }

        @media only screen and (max-width: $bp-md) {
            width: 50%;
            margin-bottom: 10px;
        }

        @media only screen and (max-width: $bp-sm) {
            width: 100%;
        }
    }

    &__chanel-img{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 5px;
        flex-shrink: 0;
        overflow: hidden;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__chanel-title{
        font-weight: 400;
        font-family: 'HelveticaNeue';
        font-size: 14px;
        line-height: 135%;
        color: $blue-dark;
        margin: auto 0;
    }
}